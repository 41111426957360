import React, { Fragment, useEffect, useState } from "react";
import { Backdrop, CircularProgress, Container } from "@mui/material";
import CheckoutOrderInformation from "../../components/CheckoutOrderInformation";
import { useLocation, useNavigate } from "react-router-dom";
import { ApiCallStatus } from "../../utils/constants";
import { removeAuthCookies } from "../../api/authentication";
import {
	fetchCheckoutData,
	fetchOrderCheckoutData,
	updateCheckoutData
} from "../PageFunctions/checkoutFunctions";
import { useAuth0 } from "@auth0/auth0-react";


const clearCookies = async () => {
	await removeAuthCookies();
};

const CheckoutOrderInformationPage = ({
	genericDataLoaded,
	genericPageContent
}) => {
	const {getAccessTokenSilently, isAuthenticated, isLoading} = useAuth0();
	const location = useLocation();
	const navigate = useNavigate();
	const [checkoutData, setCheckoutData] = useState(null);
	const [orderCheckoutInfo, setOrderCheckoutInfo] = useState(null);
	const [salesPeople, setSalesPeople] = useState([]);
	const [checkoutDataCallStatus, setCheckoutDataCallStatus] = useState(ApiCallStatus.NotStarted);
	const [orderCheckoutInfoCallStatus, setOrderCheckoutInfoCallStatus] = useState(ApiCallStatus.NotStarted);
	const [updateCheckoutDataCallStatus, setUpdateCheckoutDataCallStatus] = useState(ApiCallStatus.NotStarted);

	const scrollTop = () => {
		window.scrollTo({ top: 0, behavior: "smooth" });
	};

	const gotoLoginPage = () => {
		return navigate("/login", {state: {from: location}});
	};

	const gotoCheckoutSummaryPage = () => navigate("/checkout/summary");

	const clearCookiesAsync = async () => {
		await clearCookies();
	};

	const fetchCheckoutOrderDataAsync = async () => {
		let auth0Token;
		if (isAuthenticated === true) {
			auth0Token = await getAccessTokenSilently();
		}
		await fetchOrderCheckoutData({
			auth0Token,
			setOrderCheckoutInfo,
			setStatusInProgress: () => setOrderCheckoutInfoCallStatus(ApiCallStatus.InProgress),
			setStatusSuccess: () => setOrderCheckoutInfoCallStatus(ApiCallStatus.Succeeded),
			setStatusFailed: () => setOrderCheckoutInfoCallStatus(ApiCallStatus.Failed),
			setStatusError: () => setOrderCheckoutInfoCallStatus(ApiCallStatus.Error)
		});
	};

	const fetchCheckoutDataAsync = async () => {
		let auth0Token;
		if (isAuthenticated === true) {
			auth0Token = await getAccessTokenSilently();
		}
		await fetchCheckoutData({
			auth0Token,
			setCheckoutData,
			setStatusInProgress: () => setCheckoutDataCallStatus(ApiCallStatus.InProgress),
			setStatusSuccess: () => setCheckoutDataCallStatus(ApiCallStatus.Succeeded),
			setStatusFailed: () => setCheckoutDataCallStatus(ApiCallStatus.Failed),
			setStatusError: () => setCheckoutDataCallStatus(ApiCallStatus.Error)
		});
	};

	const updateCheckoutDataAsync = async ({checkoutData}) => {
		console.log("checkoutdata1", checkoutData);
		let auth0Token;
		if (isAuthenticated === true) {
			auth0Token = await getAccessTokenSilently();
		}
		await updateCheckoutData({
			auth0Token,
			checkoutData,
			setStatusInProgress: () => setUpdateCheckoutDataCallStatus(ApiCallStatus.InProgress),
			setStatusSuccess: () => setUpdateCheckoutDataCallStatus(ApiCallStatus.Succeeded),
			setStatusFailed: () => setUpdateCheckoutDataCallStatus(ApiCallStatus.Failed),
			setStatusError: () => setUpdateCheckoutDataCallStatus(ApiCallStatus.Error)
		});
		gotoCheckoutSummaryPage();
	};

	const authorizedPageLoad = async () => {
		await Promise.all([

		]);
	};

	const unAuthorizedPageLoad = async () => {
		await Promise.all([
			fetchCheckoutOrderDataAsync(),
			fetchCheckoutDataAsync()
		]);
	};

	useEffect(() => {
		if (genericDataLoaded === true && isLoading === false) {
			if (isAuthenticated === false) {
				clearCookiesAsync();
				gotoLoginPage();
			} else {
				authorizedPageLoad();
				unAuthorizedPageLoad();
			}
		}
	}, [genericDataLoaded, isAuthenticated, isLoading]);

	useEffect(() => {
		if (orderCheckoutInfo?.salesPeople) {
			let salesPeopleTemp = [];

			if (orderCheckoutInfo.salesPeople) {
				salesPeopleTemp = orderCheckoutInfo.salesPeople;
			}

			setSalesPeople(salesPeopleTemp);
		}
	}, [orderCheckoutInfo?.salesPeople]);

	useEffect(() => {
		if (updateCheckoutDataCallStatus === ApiCallStatus.Succeeded) {
			gotoCheckoutSummaryPage();
		}
	}, [updateCheckoutDataCallStatus]);

	const allLoadingStates = [
		checkoutDataCallStatus,
		orderCheckoutInfoCallStatus,
		updateCheckoutDataCallStatus
	];

	const pageIsLoading = allLoadingStates.includes(ApiCallStatus.InProgress) || genericDataLoaded === false || isLoading === true;

	return (
		<Container maxWidth="lg">
			<CheckoutOrderInformation
				accountType={genericPageContent?.accountType}
				email={genericPageContent?.email}
				firstName={genericPageContent?.firstName}
				lastName={genericPageContent?.lastName}
				phoneNumber={genericPageContent?.phoneNumber}
				tecfinityAccountName={genericPageContent?.tecfinityAccountName}
				tecfinityAccountNumber={genericPageContent?.tecfinityAccountNumber}
				salesPersonCode={genericPageContent?.salesPersonCode}
				salesPeople={salesPeople}
				checkoutData={checkoutData}
				scrollTop={scrollTop}
				onSubmit={updateCheckoutDataAsync}
			/>
			<Backdrop
				sx={{color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1}}
				open={pageIsLoading}
			>
				<CircularProgress color="inherit"/>
			</Backdrop>
		</Container>
	);
};

export default CheckoutOrderInformationPage;
