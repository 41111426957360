import React, { useEffect, useRef, useState } from "react";
import {
	AppBar,
	Badge,
	Box,
	Divider,
	IconButton,
	InputBase,
	List,
	ListItemButton,
	ListItemText,
	Paper,
	styled,
	Toolbar,
	Typography,
	ListItemAvatar,
	Avatar,
	Link, Drawer, Stack, Container, Button
} from "@mui/material";
import Image from "../Image";
import SearchIcon from "@mui/icons-material/Search";
import GarageOutlinedIcon from "@mui/icons-material/GarageOutlined";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import { useAuth0 } from "@auth0/auth0-react";
import useArgusSearch from "../../hooks/ArgusSearchHook";
import { SouthAfricanRand } from "../../utils/helpers";
import CustomButton from "../CustomButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import cssVariables from "../../utils/cssVariables";
import { capitalize, lowerCase, upperCase } from "lodash";
import ArrowForwardIcon from "@mui/icons-material/ArrowForwardOutlined";
import { useTheme } from "@mui/system";
import MenuIcon from "@mui/icons-material/Menu";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import { useArgusCart } from "../../contexts/ArgusCartContext";
import { ProductAvailability } from "../../utils/constants";
import { useLocation, useNavigate } from "react-router-dom";


const DesktopHeader = ({
	gotoLoginPage,
	gotoRegistrationPage,
	gotoCartPage,
	performLogout,
	cartItems,
	totalGarageItems,
	openSavedVehiclesModal,
	customerCategory,
	categoriesAndTypes,
	firstName,
	lastName,
	tecfinityAccountNumber,
	tecfinityAccountName
}) => {
	const location = useLocation();
	const { handleAddToCart } = useArgusCart();
	const { isAuthenticated, isLoading } = useAuth0();
	const { loading, pricingData, products, setSearchTerm } = useArgusSearch(customerCategory);
	const [searchInput, setSearchInput] = useState("");
	const [debouncedTerm, setDebouncedTerm] = useState(searchInput);
	const [categoriesDrawerOpen, setCategoriesDrawerOpen] = useState(false);
	const [typesDrawerOpen, setTypesDrawerOpen] = useState(false);
	const [categoryToDisplay, setCategoryToDisplay] = useState(null);
	const searchInputRef = useRef(null);

	const theme = useTheme();

	const toggleCategories = () => {
		setCategoriesDrawerOpen(!categoriesDrawerOpen);
	};

	const toggleTypes = () => {
		setTypesDrawerOpen(!typesDrawerOpen);
	};

	const handleCategoryClick = (category) => {
		setCategoryToDisplay(category);
		toggleTypes();
	}

	const handleCloseTypesClick = () => {
		setCategoryToDisplay(null);
		toggleTypes();
	}

	const StyledAppBar = styled(AppBar)(({ theme }) => ({
		backgroundColor: theme.palette.primary.main,
		color: theme.palette.text.primary,
		boxShadow: 'none',
		padding: 0,
	}));

	const StyledToolbar = styled(Toolbar)(({ theme }) => ({
		display: 'flex',
		justifyContent: 'space-between',
		paddingLeft: theme.spacing(1),  // Minimal padding
		paddingRight: theme.spacing(1),
	}));

	const NavigationLinks = styled(Box)(({ theme }) => ({
		display: 'flex',
		gap: theme.spacing(2),
		verticalAlign: "middle",
		alignItems: "center"
	}));

	const NavigationLink = styled(Link)(({ theme }) => ({
		textDecoration: 'none',
		color: theme.palette.text.primary,
		'&:hover': {
			color: theme.palette.secondary.main,
		},
		cursor: "pointer"
	}));

	const SearchBarContainer = styled(Box)(({ theme }) => ({
		position: 'relative',
		maxWidth: '500px',
		width: '100%',
		verticalAlign: "middle"
	}));

	const StyledInputBase = styled(InputBase)(({ theme }) => ({
		width: '100%',
		backgroundColor: theme.palette.background.paper,
		padding: "4px",
		fontSize: "0.8rem"
	}));

	const IconButtonContainer = styled(Box)(({ theme }) => ({
		display: 'flex',
		gap: theme.spacing(2),
	}));

	const onSearchInputChange = e => {
		const newSearchValue = e.target.value;
		setSearchInput(newSearchValue);
	}

	useEffect(() => {
		const handler = setTimeout(() => {
			if (debouncedTerm !== searchInput) {
				setDebouncedTerm(searchInput);
			}
		}, 300);

		return () => {
			clearTimeout(handler);
		};
	}, [searchInput]);

	useEffect(() => {
		if (debouncedTerm) {
			setSearchTerm(debouncedTerm);
		}
	}, [debouncedTerm]);


	return (
		<>
			<StyledAppBar position="static">
				<Container maxWidth="lg">
					<StyledToolbar>
						<CustomButton edge="start"
						              onClick={toggleCategories}
						              basic
						              startIcon={<MenuIcon fontSize="small" />}
						              color="#333"
						/>
						<Box sx={{ display: 'flex', alignItems: 'center' }}>
							<Image
								src="https://argusweb.azureedge.net/argus-web/logo.svg"
								alt="Logo"
								size="xl"
								href="/"
							/>
						</Box>

						<NavigationLinks>
							<NavigationLink variant="caption" href="/about">About</NavigationLink>
							{!isLoading && (
								<>
									{!isAuthenticated && (
										<>
											<NavigationLink variant="caption" href="#" onClick={gotoLoginPage}>Login</NavigationLink>
											<NavigationLink variant="caption" href="#" onClick={gotoRegistrationPage}>Register</NavigationLink>
										</>
									)}
									{isAuthenticated && (
										<>
											<NavigationLink variant="caption" href="#" onClick={performLogout}>Logout</NavigationLink>
											<Box sx={{
												display: "flex",
												flexDirection: "column",
												verticalAlign: "middle",
												alignItems: "left"
											}}>
												<Typography variant="caption" sx={{ textDecoration: "underline"}}>
													User:&nbsp;{firstName} {lastName}
												</Typography>
												{!!tecfinityAccountName &&
													<Typography variant="caption" sx={{ textDecoration: "underline"}}>
														Acc Name:&nbsp;{tecfinityAccountName}
													</Typography>
												}
												{!!tecfinityAccountNumber &&
													<Typography variant="caption" sx={{ textDecoration: "underline"}}>
														Acc No:&nbsp;{tecfinityAccountNumber}
													</Typography>
												}
											</Box>

										</>
									)}
								</>
							)}
						</NavigationLinks>

						{window.location.pathname.includes("cart") === false &&
							<SearchBarContainer>
								<StyledInputBase
									placeholder="Search..."
									size="small"
									autoFocus
									value={searchInput}
									inputRef={searchInputRef}
									onChange={onSearchInputChange}
									startAdornment={<SearchIcon sx={{ marginRight: '8px', color: (theme) => theme.palette.text.secondary }} />}
								/>
								{!!searchInput.trim() && !!products && !!pricingData && products.length > 0 && (
									<Paper
										sx={{
											position: 'absolute',
											top: '100%',
											left: 0,
											right: 0,
											zIndex: 1000,
											maxHeight: '300px',
											overflowY: 'auto',
											mt: 1,
										}}
									>
										<List>
											{products.map((item, index) => {
												const priceObject = pricingData.find((p) => p.sku === item.sku);
												const promoPrice = priceObject?.promo ?? 0;
												const promoInvoicePrice = priceObject?.promoInvoicePrice ?? 0;

												let itemPrice = priceObject?.price ?? 0;
												let invoicePrice = priceObject?.invoicePrice ?? 0;

												if (!!promoPrice && promoPrice > 0) {
													itemPrice = promoPrice;
													invoicePrice = promoInvoicePrice;
												}

												return (
													<React.Fragment key={index}>
														<ListItemButton component="a" href={`/product/${item.sku}`}>
															<ListItemAvatar>
																<Avatar alt={item.sku} src={item.image} />
															</ListItemAvatar>
															<ListItemText
																primary={<Typography variant="caption" sx={{ fontWeight: 'bold' }}>{item.name}</Typography>}
																secondary={
																	<>
																		<Typography variant="subtitle1" color="textPrimary">
																			{SouthAfricanRand.format(itemPrice)}
																		</Typography>
																		{invoicePrice > 0 && invoicePrice !== itemPrice && (
																			<Typography variant="subtitle2" color="textSecondary">
																				Invoice: {SouthAfricanRand.format(invoicePrice)}
																			</Typography>
																		)}
																	</>
																}
															/>
															{item.availability !== ProductAvailability.No_Stock &&
																<IconButton color="error"
																            onClick={(e) => {
																	            e.preventDefault();
																	            handleAddToCart({
																		            id: item.productId,
																		            name: item.name,
																		            vendor: item.vendor,
																		            image: item.image,
																		            sku: item.sku,
																	            });
																            }}>
																	<AddShoppingCartIcon fontSize="small" />
																</IconButton>
															}

														</ListItemButton>
														{index < products.length - 1 && <Divider />}
													</React.Fragment>
												);
											})}
										</List>
									</Paper>
								)}
							</SearchBarContainer>
						}


						{/* Icons */}
						<IconButtonContainer>
							<IconButton edge="end" onClick={openSavedVehiclesModal}>
								<Badge badgeContent={totalGarageItems} color="error">
									<GarageOutlinedIcon sx={{ color: theme => theme.palette.text.primary }} fontSize="medium" />
								</Badge>
							</IconButton>
							<IconButton edge="end" onClick={gotoCartPage}>
								<Badge badgeContent={cartItems} color="error">
									<ShoppingCartIcon sx={{ color: theme => theme.palette.text.primary }} fontSize="medium" />
								</Badge>
							</IconButton>
						</IconButtonContainer>
					</StyledToolbar>
				</Container>
			</StyledAppBar>

			<Drawer
				anchor="left"
				open={categoriesDrawerOpen}
				onClose={toggleCategories}
				sx={{ width: '25%', '& .MuiDrawer-paper': { width: '25%' } }}
			>
				<Box sx={{ padding: "2px", backgroundColor: theme.palette.background.default }}>
					<Paper sx={{ width: "100%", marginBottom: "1rem", position: 'sticky', top: 0, zIndex: 1100 }}>
						<Box sx={{ width: "100%" }} edge="start">
							<Stack direction="column">
								<Box sx={{ width: "100%" }} edge="start">
									<Image
										src="https://argusweb.azureedge.net/argus-web/logo.svg"
										alt="Logo"
										size="lg"
									/>
								</Box>
								<Box sx={{ alignItems: "left", paddingLeft: "1rem" }}>
									<Typography variant="body1" gutterBottom>
										Shop by Category
									</Typography>
								</Box>
							</Stack>
						</Box>
					</Paper>
					<Paper sx={{ width: "100%", marginBottom: "1rem" }}>
						<List>
							{categoriesAndTypes.map((category, i) => {
								const categoryName = capitalize(category.category.replaceAll("_", " ").toLowerCase());

								return (
									<div key={i}>
										<ListItemButton href="#" onClick={() => handleCategoryClick(category)}>
											<ListItemText disableTypography>
												<Typography variant="caption">{categoryName}</Typography>
											</ListItemText>
											<IconButton sx={{ flexShrink: 0 }} edge="end">
												<ArrowForwardIcon fontSize="small" />
											</IconButton>
										</ListItemButton>
										{i < categoriesAndTypes.length - 1 && <Divider component="li" />}
									</div>
								);
							})}
						</List>
					</Paper>
				</Box>
			</Drawer>

			{!!categoryToDisplay && (
				<Drawer
					anchor="left"
					open={typesDrawerOpen}
					onClose={handleCloseTypesClick}
					sx={{ width: '25%', '& .MuiDrawer-paper': { width: '25%' } }}
				>
					<Box sx={{ padding: "2px", backgroundColor: theme.palette.background.default }}>
						<Paper sx={{ width: "100%", marginBottom: "1rem", position: 'sticky', top: 0, zIndex: 1100 }}>
							<Box sx={{ width: "100%" }} edge="start">
								<Stack direction="column">
									<Box sx={{ alignItems: "left", paddingTop: "1rem" }} onClick={handleCloseTypesClick}>
										<CustomButton text="Shop by category"
										              startIcon={<ArrowBackIcon />}
										              color={cssVariables.blueButtonColor}
										              basic />
									</Box>
									<Box sx={{ alignItems: "left", paddingLeft: "1rem" }}>
										<Typography variant="h6" gutterBottom>
											{capitalize(categoryToDisplay.category.replaceAll("_", " ").toLowerCase())}
										</Typography>
									</Box>
								</Stack>
							</Box>
						</Paper>
						<Paper sx={{ width: "100%", marginBottom: "1rem" }}>
							<List>
								{categoryToDisplay.productTypes.map((type, i) => {
									const categorySlug = lowerCase(categoryToDisplay.category).replaceAll(" ", "_").trim();
									let typeName;
									let typeSlug;
									if (lowerCase(type) === "view all") {
										typeName = upperCase(type);
										typeSlug = `/products/${categorySlug}`;
									} else {
										typeName = capitalize(type.replaceAll("_", " ").trim());
										typeSlug = `/products/${categorySlug}/${type.toLowerCase()}`;
									}

									return (
										<div key={i}>
											<ListItemButton href={typeSlug}>
												<ListItemText disableTypography>
													<Typography variant="caption">{typeName}</Typography>
												</ListItemText>
											</ListItemButton>
											{i < categoryToDisplay.productTypes.length - 1 && <Divider component="li" />}
										</div>
									);
								})}
							</List>
						</Paper>
					</Box>
				</Drawer>
			)}
		</>

	)
}

export default DesktopHeader;