import axios from "axios";
import axiosRetry from "axios-retry";
import { ApiHeaders } from "../utils/constants";

const orderUrl = process.env.REACT_APP_ORDER_API_URL;

export const postCheckout = async (items, auth0Token) => {
	try {
		const instance = axios.create({
			withCredentials: true,
			baseURL: `${orderUrl}/api/checkout/`,
			headers: {
				"Content-type": 'application/json',
				[ApiHeaders.WebsiteId]: process.env.REACT_APP_WEBSITE_ID
			},
		});

		axiosRetry(instance, { retries: 3, retryDelay: axiosRetry.exponentialDelay });

		if (!!auth0Token) {
			instance.defaults.headers.common["Authorization"] = `Bearer ${auth0Token}`;
		}

		const itemsBody = items.map(item => {
			return {
				sku: item.sku,
				quantity: item.quantity
			}
		});

		const body = { items: itemsBody };

		return await instance
			.post(``, body)
			.then((response) => {
				if (response.status === 200)
					return {
						error: false,
						success: true,
						message: response.data.message,
						data: response.data.data
					};
				else
					return {
						error: true,
						success: false,
						message: response.data.message
					};
			})
			.catch((error) => {
				if (error.response) {
					console.error("Server Error:", error.response.status);
				}
				else if (error.request) {
					console.error("Network Error:", error.request);
				}
				else {
					console.error("Error:", error.message);
				}
			});
	}
	catch (error) {
		console.error(error);
		return {
			error: true,
			success: false,
			message: "Something went wrong. Please try again."
		};
	}
};

export const putCheckout = async (checkoutData, auth0Token) => {
	try {
		const instance = axios.create({
			withCredentials: true,
			baseURL: `${orderUrl}/api/checkout/`,
			headers: {
				"Content-type": 'application/json',
				[ApiHeaders.WebsiteId]: process.env.REACT_APP_WEBSITE_ID
			},
		});

		console.log("checkoutdata", checkoutData);

		axiosRetry(instance, { retries: 3, retryDelay: axiosRetry.exponentialDelay });

		if (!!auth0Token) {
			instance.defaults.headers.common["Authorization"] = `Bearer ${auth0Token}`;
		}

		if (!!checkoutData?.orderItems && checkoutData.orderItems === "null") {
			checkoutData.orderItems = null;
		}

		const requestUrl = ``;
		return await instance
			.put(requestUrl, checkoutData)
			.then((response) => {
				if (response.status === 200 || response.status === 204)
					return {
						error: false,
						success: true,
						message: response.data.message,
						data: response.data.data
					};
				else
					return {
						error: true,
						success: false,
						message: response.data.message
					};
			})
			.catch((error) => {
				if (error.response) {
					console.error("Server Error:", error.response.status);
				}
				else if (error.request) {
					console.error("Network Error:", error.request);
				}
				else {
					console.error("Error:", error.message);
				}
			});
	}
	catch (error) {
		console.error(error);
		return {
			error: true,
			success: false,
			message: "Something went wrong. Please try again."
		};
	}
};

export const putReactivateCheckout = async (orderId, auth0Token) => {
	try {
		const instance = axios.create({
			withCredentials: true,
			baseURL: `${orderUrl}/api/checkout/`,
			headers: {
				"Content-type": 'application/json',
				[ApiHeaders.WebsiteId]: process.env.REACT_APP_WEBSITE_ID
			},
		});

		axiosRetry(instance, { retries: 3, retryDelay: axiosRetry.exponentialDelay });

		if (!!auth0Token) {
			instance.defaults.headers.common["Authorization"] = `Bearer ${auth0Token}`;
		}


		const requestUrl = ``;
		return await instance
			.put(requestUrl, checkoutData)
			.then((response) => {
				if (response.status === 200 || response.status === 204)
					return {
						error: false,
						success: true,
						message: response.data.message,
						data: response.data.data
					};
				else
					return {
						error: true,
						success: false,
						message: response.data.message
					};
			})
			.catch((error) => {
				if (error.response) {
					console.error("Server Error:", error.response.status);
				}
				else if (error.request) {
					console.error("Network Error:", error.request);
				}
				else {
					console.error("Error:", error.message);
				}
			});
	}
	catch (error) {
		console.error(error);
		return {
			error: true,
			success: false,
			message: "Something went wrong. Please try again."
		};
	}
};

export const getCheckoutData = async (auth0Token) => {
	try {
		const instance = axios.create({
			withCredentials: true,
			baseURL: `${orderUrl}/api/checkout`,
			headers: {
				"Content-type": 'application/json',
				[ApiHeaders.WebsiteId]: process.env.REACT_APP_WEBSITE_ID
			},
		});

		axiosRetry(instance, { retries: 3, retryDelay: axiosRetry.exponentialDelay });

		if (!!auth0Token) {
			instance.defaults.headers.common["Authorization"] = `Bearer ${auth0Token}`;
		}

		const requestUrl = "";
		return await instance
			.get(requestUrl)
			.then((response) => {
				if (response.status === 200)
					return {
						error: false,
						success: true,
						message: response.data.message,
						data: response.data.data
					};
				else if (response.status === 204)
					return {
						error: false,
						success: true,
						message: "no checkout session found"
					}
				else
					return {
						error: true,
						success: false,
						message: response.data.message
					};
			})
			.catch((error) => {
				if (error.response) {
					console.error("Server Error:", error.response.status);
				}
				else if (error.request) {
					console.error("Network Error:", error.request);
				}
				else {
					console.error("Error:", error.message);
				}
			});
	}
	catch (e) {
		return {
			error: true,
			success: false,
			message: "Something went wrong. Please try again."
		};
	}
};

export const getAddressCheckoutData = async (auth0Token) => {
	try {
		const instance = axios.create({
			withCredentials: true,
			baseURL: `${orderUrl}/api/checkout`,
			headers: {
				"Content-type": 'application/json',
				[ApiHeaders.WebsiteId]: process.env.REACT_APP_WEBSITE_ID
			},
		});

		axiosRetry(instance, { retries: 3, retryDelay: axiosRetry.exponentialDelay });

		if (auth0Token) {
			instance.defaults.headers.common["Authorization"] =
				`Bearer ${auth0Token}`;
		}

		const requestUrl = `/address`;
		return await instance
			.get(requestUrl)
			.then((response) => {
				if (response.status === 200)
					return {
						error: false,
						success: true,
						message: response.data.message,
						data: response.data.data
					};
				else
					return {
						error: true,
						success: false,
						message: response.data.message
					};
			})
			.catch((error) => {
				if (error.response) {
					console.error("Server Error:", error.response.status);
				}
				else if (error.request) {
					console.error("Network Error:", error.request);
				}
				else {
					console.error("Error:", error.message);
				}
			});
	}
	catch (e) {
		return {
			error: true,
			success: false,
			message: "Something went wrong. Please try again."
		};
	}
};

export const getOrderCheckoutData = async (auth0Token) => {
	try {
		const instance = axios.create({
			withCredentials: true,
			baseURL: `${orderUrl}/api/checkout`,
			headers: {
				"Content-type": 'application/json',
				[ApiHeaders.WebsiteId]: process.env.REACT_APP_WEBSITE_ID
			},
		});

		axiosRetry(instance, { retries: 3, retryDelay: axiosRetry.exponentialDelay });

		if (!!auth0Token) {
			instance.defaults.headers.common["Authorization"] =
				`Bearer ${auth0Token}`;
		}

		const requestUrl = "/order";
		return await instance
			.get(requestUrl)
			.then((response) => {
				if (response.status === 200)
					return {
						error: false,
						success: true,
						message: response.data.message,
						data: response.data.data
					};
				else
					return {
						error: true,
						success: false,
						message: response.data.message
					};
			})
			.catch((error) => {
				if (error.response) {
					console.error("Server Error:", error.response.status);
				}
				else if (error.request) {
					console.error("Network Error:", error.request);
				}
				else {
					console.error("Error:", error.message);
				}
			});
	}
	catch (e) {
		return {
			error: true,
			success: false,
			message: "Something went wrong. Please try again."
		};
	}
};

export const getCheckoutLogisticsFeeData = async (subtotal, auth0Token) => {
	try {
		const instance = axios.create({
			withCredentials: true,
			baseURL: `${orderUrl}/api/checkout`,
			headers: {
				"Content-type": 'application/json',
				[ApiHeaders.WebsiteId]: process.env.REACT_APP_WEBSITE_ID
			},
		});

		axiosRetry(instance, { retries: 3, retryDelay: axiosRetry.exponentialDelay });

		if (auth0Token) {
			instance.defaults.headers.common["Authorization"] =
				`Bearer ${auth0Token}`;
		}

		const searchParams = new URLSearchParams();
		if (subtotal) searchParams.append("subtotal", subtotal);

		const requestUrl = `/logistics-fee?${searchParams.toString()}`;
		return await instance
			.get(requestUrl)
			.then((response) => {
				if (response.status === 200)
					return {
						error: false,
						success: true,
						message: response.data.message,
						data: response.data.data
					};
				else
					return {
						error: true,
						success: false,
						message: response.data.message
					};
			})
			.catch((error) => {
				if (error.response) {
					console.error("Server Error:", error.response.status);
				}
				else if (error.request) {
					console.error("Network Error:", error.request);
				}
				else {
					console.error("Error:", error.message);
				}
			});
	}
	catch (e) {
		return {
			error: true,
			success: false,
			message: "Something went wrong. Please try again."
		};
	}
};
