import React, { useEffect, useState } from "react";
import { AccountType, LogisticsMethod, ScreenSize } from "../../utils/constants";
import { find } from "lodash";
import { CartSummary } from "../CartSummary";
import useSystemHook from "../../hooks/SystemHook";
import {
	Alert,
	Box,
	Button,
	Collapse,
	Divider,
	Grid,
	IconButton,
	List,
	ListItem,
	ListItemText,
	Paper,
	Typography
} from "@mui/material";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import EditIcon from "@mui/icons-material/Edit";
import HomeIcon from "@mui/icons-material/Home";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import PageHeader from "../PageHeader";

const CheckoutSummary = ({
	checkoutData,
	accountType,
	userAddresses,
	collectionAddresses,
	salesPeople,
	tecfinityAccountName,
	tecfinityAccountNumber,
	countries,
	provinces,
	gotoCheckoutOrderInformation,
	gotoCheckoutBillingAddress,
	gotoCheckoutCollectionAddress,
	gotoCheckoutDeliveryMethod,
	gotoCheckoutShippingAddress,
	onSubmit
}) => {
	const { screenSize } = useSystemHook();
	const [salesPerson, setSalesPerson] = useState(null);
	const [billingAddress, setBillingAddress] = useState(null);
	const [shippingAddress, setShippingAddress] = useState(null);
	const [collectionAddress, setCollectionAddress] = useState(null);
	const [showValidationErrors, setShowValidationErrors] = useState(null);

	const scrollTop = () => window.scrollTo(0, 0);

	const onGoToPaymentClick = async () => {
		if (!checkoutData?.billingAddressId || !checkoutData?.logisticsMethod ||
			(checkoutData?.logisticsMethod === LogisticsMethod.Ship && !checkoutData?.shippingAddressId)) {
			setShowValidationErrors(true);
		}
		await onSubmit(checkoutData);
	};

	const onChangeSectionClick = (sectionHandler) => {
		setShowValidationErrors(false);
		sectionHandler();
	};

	const renderAddress = (address) => (
		<List disablePadding>
			<ListItem>
				<ListItemText primary={<Typography variant="caption">{`${address?.firstName} ${address?.lastName}`}</Typography>} />
			</ListItem>
			<ListItem>
				<ListItemText primary={<Typography variant="caption">{`${address?.streetAddress}, ${address?.city}`}</Typography>} />
			</ListItem>
			<ListItem>
				<ListItemText primary={<Typography variant="caption">{`${address?.province}, ${address?.country}`}</Typography>} />
			</ListItem>
		</List>
	);

	const updateAddressData = (addressId, addresses, setAddress) => {
		const address = find(addresses, (x) => x.addressId === addressId);
		if (!!address) {
			const country = find(countries, (x) => x.countryId === address.countryId);
			const province = find(provinces, (x) => x.provinceId === address.provinceId);
			address.country = country?.name ?? "";
			address.province = province?.name ?? "";
			setAddress(address);
		}
	};

	useEffect(scrollTop, []);

	useEffect(() => {
		if (checkoutData) {
			if (checkoutData.billingAddressId) {
				updateAddressData(checkoutData.billingAddressId, userAddresses, setBillingAddress);
			}
			if (checkoutData.shippingAddressId) {
				updateAddressData(checkoutData.shippingAddressId, userAddresses, setShippingAddress);
			}
			if (checkoutData.collectionAddressId) {
				updateAddressData(checkoutData.collectionAddressId, collectionAddresses, setCollectionAddress);
			}
			if (checkoutData.salesPersonCode) {
				setSalesPerson(find(salesPeople, (x) => x.code === checkoutData.salesPersonCode));
			}
		}
	}, [checkoutData, userAddresses, collectionAddresses, salesPeople]);

	
	return (
		<>
			<PageHeader title="Checkout" />
			<Box sx={{ minHeight: '100vh', backgroundColor: '#f7f7f7', padding: { xs: 2, md: 3 } }}>
				{!!checkoutData && (
					<Grid container spacing={2}>
						<Grid item xs={12} md={9}>
							{[
								{
									title: "Order Information",
									icon: ShoppingCartIcon,
									sectionClickHandler: gotoCheckoutOrderInformation,
									details: [
										{
											label: "Email",
											value: checkoutData.email
										},
										accountType === AccountType.Commercial && { label: "Account", value: `${tecfinityAccountName} - ${tecfinityAccountNumber}` },
										salesPerson && { label: "Sales Person", value: salesPerson.name },
										checkoutData?.customerReference && { label: "Reference", value: checkoutData.customerReference }
									]
								},
								{
									title: "Billing Address",
									icon: HomeIcon,
									sectionClickHandler: gotoCheckoutBillingAddress,
									details: [
										{
											addressData: billingAddress,
											showAlert: !billingAddress && showValidationErrors
										}
									]
								},
								{
									title: "Delivery Method",
									icon: LocalShippingIcon,
									sectionClickHandler: gotoCheckoutDeliveryMethod,
									details: [
										{
											label: "Method",
											value: checkoutData?.logisticsMethod === LogisticsMethod.Ship ? 'Delivery' : 'Collection'
										}
									]
								}
							].map(({ title, icon: Icon, sectionClickHandler, details }, idx) => (
								<Paper key={idx} elevation={3} sx={{ padding: { xs: 2, md: 3 }, mb: 2 }}>
									<Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
										<Icon fontSize="small" sx={{ mr: 1 }} />
										<Typography variant="body2" sx={{ flex: 1 }}>{title}</Typography>
										{screenSize === ScreenSize.Desktop ? (
											<Button
												size="small"
												variant="outlined"
												color="error"
												startIcon={<EditIcon fontSize="small" />}
												onClick={() => onChangeSectionClick(sectionClickHandler)}
											>
												Change
											</Button>
										) : (
											<IconButton size="small" color="error" onClick={() => onChangeSectionClick(sectionClickHandler)}>
												<EditIcon fontSize="small" />
											</IconButton>
										)}
									</Box>
									<List disablePadding>
										{details.map((detail, i) => {
											if (!detail) return null;
											else if (!!detail.label) {
												return (
													<ListItem key={i}>
														<ListItemText primary={<Typography variant="caption"><b>{detail.label}:</b> {detail.value}</Typography>} />
													</ListItem>
												)
											} else if (!!detail.addressData) {
												return renderAddress(detail.addressData)
											} else if (!!detail.showAlert) {
												return (
													<Alert key={i} severity="warning">Please provide {title.toLowerCase()}</Alert>
												)
											}
										})}
									</List>
									{idx === 2 && checkoutData.logisticsMethod === LogisticsMethod.Ship && shippingAddress && (
										<>
											<Divider sx={{ my: 2 }} />
											<Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
												<LocalShippingIcon fontSize="small" sx={{ mr: 1 }} />
												<Typography variant="body2" sx={{ flex: 1 }}>Shipping Address</Typography>
											</Box>
											{renderAddress(shippingAddress)}
										</>
									)}
								</Paper>
							))}
						</Grid>
						<Grid item xs={12} md={3}>
							<CartSummary
								vat={checkoutData.vatPercentage}
								cartNettTotal={checkoutData.subtotal}
								settlementAmount={checkoutData.settlementAmount}
								vatAmount={checkoutData.vatAmount}
								logisticsFee={checkoutData.logisticsFee}
								total={checkoutData.total}
								buttonText="Go to Payment"
								onButtonClick={onGoToPaymentClick}
								buttonDisabled={!billingAddress || !checkoutData?.logisticsMethod || (checkoutData.logisticsMethod === LogisticsMethod.Ship && !shippingAddress)}
								sx={{ mb: '2rem' }}
							/>
						</Grid>
					</Grid>
				)}
			</Box>
		</>
	);
};

export default CheckoutSummary;
