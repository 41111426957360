import React, { useEffect, useState } from "react";
import * as yup from "yup";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { AccountType, ScreenSize } from "../../utils/constants";
import { cloneDeep } from "lodash";
import { CartSummary } from "../CartSummary";
import useSystemHook from "../../hooks/SystemHook";
import { Box, Grid, MenuItem, Paper, Typography, TextField, Autocomplete } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import FingerprintIcon from '@mui/icons-material/Fingerprint';
import EmailIcon from "@mui/icons-material/Email";
import PhoneIcon from "@mui/icons-material/Phone";
import BookmarkIcon from '@mui/icons-material/Bookmark';
import PageHeader from "../PageHeader";

const CheckoutOrderInformation = ({
	salesPeople,
	accountType,
	phoneNumber,
	firstName,
	lastName,
	email,
	tecfinityAccountName,
	tecfinityAccountNumber,
	salesPersonCode,
	checkoutData,
	scrollTop,
	onSubmit
}) => {
	const [salesPersonOptions, setSalesPersonOptions] = useState([]);

	const schema = yup.object({
		salesPerson: yup.string(),
		email: yup.string().email().required("Email is required"),
		phone: yup.string().required("Mobile number is required to register"),
		customerReference: yup.string().nullable().max(10, "Reference must be at most 10 characters")
	}).required();

	const { control, handleSubmit, trigger, getValues, setValue, formState: { errors } } = useForm({
		resolver: yupResolver(schema),
		defaultValues: { 
			salesPerson: "No Sales Person", 
			email: "", 
			phone: "",
			customerReference: checkoutData?.customerReference }
	});

	useEffect(() => {
		if (salesPeople) setSalesPersonOptions(salesPeople.map(x => x.name).sort());
	}, [salesPeople]);

	useEffect(() => {
		const salesPerson = salesPeople.find(x => String(x.code) === String(salesPersonCode));
		if (salesPerson) setValue("salesPerson", salesPerson.name ?? "No Sales Person");
	}, [salesPersonCode, salesPeople, setValue]);

	useEffect(() => {
		if (!!checkoutData?.customerReference) {
			setValue("customerReference", checkoutData.customerReference);
		} else {
			setValue("customerReference", "");
		}
	}, [checkoutData?.customerReference, setValue]);

	useEffect(() => setValue("phone", phoneNumber ?? ""), [phoneNumber, setValue]);
	useEffect(() => setValue("firstName", firstName ?? ""), [firstName, setValue]);
	useEffect(() => setValue("lastName", lastName ?? ""), [lastName, setValue]);
	useEffect(() => setValue("email", email ?? ""), [email, setValue]);

	const onSaveOrderInformationClick = async () => {
		const isValid = await trigger(["phone", "email", "customerReference"]);
		if (!isValid) {
			scrollTop();
			return;
		}
		if (isValid && checkoutData) {
			const checkoutDataClone = cloneDeep(checkoutData);
			const salesPerson = salesPeople.find(x => x.name.trim() === getValues("salesPerson"));
			checkoutDataClone.salesPersonCode = salesPerson?.code ?? "155";
			checkoutDataClone.phoneNumber = getValues("phone");
			checkoutDataClone.email = getValues("email");
			checkoutDataClone.customerReference = getValues("customerReference");
			await onSubmit({ checkoutData: checkoutDataClone });
		}
	};

	return (
		<>
			<PageHeader title="Checkout" />
			<Box sx={{ backgroundColor: '#f7f7f7', minHeight: '100vh', padding: { xs: 1, md: 2 } }}>
				{!!checkoutData && (
					<Grid container spacing={2}>
						<Grid item xs={12} md={9}>
							<Paper elevation={3} sx={{ padding: { xs: '1rem', md: '1.5rem' }, marginBottom: '1.5rem' }}>
								<form onSubmit={handleSubmit(onSubmit)}>
									<Typography variant="body2" sx={{ display: 'flex', alignItems: 'center', mb: 4 }}>
										<BookmarkIcon fontSize="small" sx={{ mr: '0.5rem' }} />
										Order Information
									</Typography>
									<Controller
										name="salesPerson"
										control={control}
										render={({ field }) => (
											<Autocomplete
												{...field}
												options={salesPersonOptions}
												getOptionLabel={(option) => option}
												onChange={(event, value) => field.onChange(value)}
												renderInput={(params) => (
													<TextField
														{...params}
														label="Sales Person"
														variant="outlined"
														fullWidth
														size="small"
														error={!!errors.salesPerson}
														helperText={errors.salesPerson?.message}
														InputProps={{
															...params.InputProps,
															startAdornment: params.InputProps.startAdornment,
															endAdornment: params.InputProps.endAdornment,
														}}
														sx={{ mb: 5 }}
													/>
												)}
												clearOnEscape
												isOptionEqualToValue={(option, value) => option === value}
												sx={{ '& .MuiAutocomplete-option': { fontSize: '0.875rem' } }}
											/>
										)}
									/>
									<Controller
										name="customerReference"
										control={control}
										render={({ field }) => (
											<TextField
												label="Reference"
												variant="outlined"
												fullWidth
												{...field}
												size="small"
												error={!!errors?.customerReference}
												helperText={errors?.customerReference?.message ?? "Optional"}
												sx={{ mb: 5 }}
											/>
										)}
									/>
									{accountType === AccountType.Commercial && (
										<Box sx={{ marginBottom: '1.5rem' }}>
											<Typography variant="body2" sx={{ display: 'flex', alignItems: 'center', mb: 4 }}>
												<InfoIcon fontSize="small" sx={{ mr: '0.5rem' }} />
												Account
											</Typography>
											<TextField
												label="Account"
												variant="outlined"
												fullWidth
												size="small"
												value={`${tecfinityAccountName} - ${tecfinityAccountNumber}`}
												disabled
												InputProps={{ startAdornment: <AccountCircleIcon fontSize="small" sx={{ mr: '0.5rem' }} /> }}
												sx={{ mb: 5 }}
											/>
										</Box>
									)}

									<Typography variant="body2" sx={{ display: 'flex', alignItems: 'center', mt: 4, mb: 4 }}>
										<InfoIcon sx={{ mr: '0.5rem' }} fontSize="small" />
										Contact Information
									</Typography>

									<Controller
										name="email"
										control={control}
										render={({ field }) => (
											<TextField
												label="Email"
												variant="outlined"
												fullWidth
												{...field}
												size="small"
												error={!!errors.email}
												helperText={errors.email?.message}
												InputProps={{ startAdornment: <EmailIcon sx={{ mr: '0.5rem' }} fontSize="small" /> }}
												disabled={!!accountType}
												sx={{ mb: 5 }}
											/>
										)}
									/>

									<Controller
										name="phone"
										control={control}
										render={({ field }) => (
											<TextField
												label="Phone"
												variant="outlined"
												fullWidth
												size="small"
												{...field}
												error={!!errors.phone}
												helperText={errors.phone?.message}
												InputProps={{ startAdornment: <PhoneIcon sx={{ mr: '0.5rem' }} fontSize="small" /> }}
												disabled={!!accountType}
												sx={{ mb: 5 }}
											/>
										)}
									/>
								</form>
							</Paper>
						</Grid>

						{/* Cart Summary */}
						<Grid item xs={12} md={3}>
							<CartSummary
								vat={checkoutData.vatPercentage}
								cartNettTotal={checkoutData.subtotal}
								settlementAmount={checkoutData.settlementAmount}
								vatAmount={checkoutData.vatAmount}
								logisticsFee={checkoutData.logisticsFee}
								total={checkoutData.total}
								buttonText="Continue"
								onButtonClick={onSaveOrderInformationClick}
							/>
						</Grid>
					</Grid>
				)}
			</Box>
		</>
	);
};

export default CheckoutOrderInformation;
